$(document).ready(function () {
    //forms vars
    var hintOpenSpeed = 200; // Length of open animation for field hints
    var closeDelay = 100;
    var hintCloseSpeed = 100;
    var errorClass = "apiform-isError";
    var submittingClass = "apiform-isSubmitting";
    var errorContainerDataAttribute = "errorContainer";

    //custom validators
    $.validator.addMethod("pwcheck", function (value, element) {
        var isValid = false;

        var UpperCaseRegexp = new RegExp(/[A-Z]/);
        var LowerCaseRegexp = new RegExp(/[a-z]/);
        var NumberRegexp = new RegExp(/[0-9]/);
        var SpecialRegexp = new RegExp(/[\.,_\-!@#$%^&*\(\)=+\\|\[\];:'"<>\/\?{}]/);
        
        if (value.length >= 8 &&
            value.match(UpperCaseRegexp) !== null &&
            value.match(LowerCaseRegexp) !== null &&
            value.match(NumberRegexp) !== null &&
            value.match(SpecialRegexp) !== null
            ) {
            isValid = true;
        }
        
        if (!isValid) {
            $.validator.messages.pwcheck = "Password does not meet complexity requirements.";
        }
        //attach checkmark validation
        /*if (isValid) {
            $(element).parent().addClass("valid");
        } else {
            $(element).parent().removeClass("valid");
        }*/
        return isValid;

    });

    //Forms hint defaults
    $("form input").on("focus", function (e) {
        var hint = findHintContainerForElement($(this));
        if (hint) {
            $(hint).slideDown(hintOpenSpeed);
        }
    }).on("focusout", function (e) {
        var hint = findHintContainerForElement($(this));
        if (hint) {
            window.setTimeout(function () { $(hint).slideUp(hintCloseSpeed); }, closeDelay);
        }
    });
});

var findHintContainerForElement = function (ele) {
    var form = $(ele).parents('form');
    if (!form.length) return;

    var fieldId = $(ele).attr('id');
    var hintContainer = $('[data-hintfor="' + fieldId + '"]', form);
    if (!hintContainer.length) return;

    return hintContainer;
};
