$(document).ready(function () {
    $(".stateLocation").each(function () {
        
        var $thisPageDiv = $(this);
        var map;
        var $map = $('#google_map');
        var $map_dom = $map.first();
        var $mapBanner = $('.map-banner');
        var mapBoundsNe = []; //unused
        var mapBoundsSw = []; //unused
        var initLatLon = null;
        var initMapZoom = 5;
        var initMapZoomMobile = 3;
        var maxMapZoom = 10;
        var currentZoom = 0;
        var Markers = [];
        var iconWidth = 88;
        var iconHeight = 88;
        var iconWidthScaledDown = 44;
        var iconHeightScaledDown = 44;
        var iconFontSize = 50;
        var iconLeftOffset = 44;
        var iconTopOffset = 68;
        
        var imgMarkerPreload1 = new Image;
        var imgMarkerPreload2 = new Image;
        imgMarkerPreload1.onload = function () {
            StateLocationMapController.Init();
        };
        imgMarkerPreload1.src = "/dist/img/ech-blue-circle-star.png";
        
        var StateLocationMapController = {
            Init: function () {
                var self = this;
                loadGoogleMapTimer = setInterval(function () {
                    if (google_map_loaded) {
                        clearInterval(loadGoogleMapTimer);
                        self.RenderGoogleMap();
                    }
                }, 100);
            },
            RenderGoogleMap: function () {
                var self = this;
                map = null;
                var myZoom = initMapZoom;
                if ($(window).width() < 767) {
                    myZoom = initMapZoomMobile;
                }
                var myOptions = {
                    'zoom': myZoom,
                    'mapTypeId': google.maps.MapTypeId.ROADMAP,
                    panControl: true,
                    //zoomControl: false,
                    scrollwheel: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    overviewMapControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    styles: [
                        {
                            "featureType": "landscape.man_made",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#e9e5dc"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape.natural",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#b9ced4"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.business",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.medical",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#ccdca1"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.sports_complex",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "hue": "#ff0000"
                                },
                                {
                                    "saturation": -100
                                },
                                {
                                    "lightness": 99
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#808080"
                                },
                                {
                                    "lightness": 54
                                },
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#767676"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#dcebef"
                                }
                            ]
                        }
                    ]
                };

                if (citiesResults !== null && citiesResults.length > 0) {
                    myOptions.center = new google.maps.LatLng(citiesResults[0].Latitude, citiesResults[0].Longitude); //set default?
                }

                //myOptions.center = new google.maps.LatLng(countryLocationServerVars.defaultCenterLatLon[0], countryLocationServerVars.defaultCenterLatLon[1]); //set default?
                map = new google.maps.Map(document.getElementById('google_map'), myOptions);
                google.maps.event.addListenerOnce(map, 'idle', function () {

                    // do something only the first time the map is loaded
                    var mapBounds = new google.maps.LatLngBounds();
                    
                    if (!countryLocationServerVars.useDynamicData) {
                        if (citiesResults !== null && citiesResults.length > 0) {
                            $.each(citiesResults, function (cidx) {
                                if (citiesResults[cidx].Latitude !== 0 && citiesResults[cidx].Longitude !== 0) {
                                    var latlng = new google.maps.LatLng(citiesResults[cidx].Latitude, citiesResults[cidx].Longitude);
                                    mapBounds.extend(latlng);
                                }
                            });
                        }
                    }

                    map.fitBounds(mapBounds); // auto - zoom
                    map.panToBounds(mapBounds); // auto - center
                    
                    currentZoom = map.getZoom();
                    $("#google_map").addClass("initialized");
                    /*setTimeout(function () {
                        console.log(map.getCenter());
                    }, 1000);*/
                });
                google.maps.event.addListener(map, 'zoom_changed', function () {
                    //console.log("mapZoom:" + map.getZoom());
                    currentZoom = map.getZoom();
                    zoomChangeBoundsListener =
                        google.maps.event.addListener(map, 'bounds_changed', function (event) {
                            if (maxMapZoom > -1) {
                                //console.log(this.getZoom());
                                if (this.getZoom() > maxMapZoom) {
                                    // Change max/min zoom here
                                    this.setZoom(maxMapZoom);
                                    this.initialZoom = false;
                                }

                                google.maps.event.removeListener(zoomChangeBoundsListener);
                            }
                        });
                });
                this.PopulateMap();
            },
            PopulateMap: function () {
                Markers = [];
                //umbraco data or real data?
                if (!countryLocationServerVars.useDynamicData) {     
                    if (citiesResults !== null && citiesResults.length > 0) {
                        
                        $.each(citiesResults, function (cidx) {
                            //each city
                            if (citiesResults[cidx].Latitude !== 0 && citiesResults[cidx].Longitude !== 0) {
                                //console.log("city", statesResults[idx].Cities[cidx]);
                                //add marker to map
                                var latlng = new google.maps.LatLng(citiesResults[cidx].Latitude, citiesResults[cidx].Longitude);
                                //
                                var iconSrc = window.GRGExtensions.GoogleMapsHelper.createMarker(iconWidth, iconHeight, "", imgMarkerPreload1, "normal " + iconFontSize + "px Hind", [iconLeftOffset, iconTopOffset]);
                                var myIcon = new google.maps.MarkerImage(iconSrc, null, null, null, new google.maps.Size(iconWidthScaledDown, iconHeightScaledDown));
                                //
                                //var myIcon = { url: imgMarkerPreload1.src, scaledSize: place_icon_scaled_size };
                                var myMarker = new google.maps.Marker({
                                    map: map,
                                    position: latlng,
                                    title: citiesResults[cidx].CityTitle,
                                    url: citiesResults[cidx].CityUrl,
                                    icon: myIcon,
                                    optimized: false
                                });
                                google.maps.event.addListener(myMarker, 'click', function () {
                                    window.location.href = this.url;
                                    $mapBanner.addClass("loading");
                                    setTimeout(function () { $mapBanner.addClass("loadingIn"); }, 20);
                                    $map.addClass("loading");
                                });
                                Markers.push(myMarker);
                            }
                        });
                            
                        var myoverlay = new google.maps.OverlayView();
                        myoverlay.draw = function () {
                            this.getPanes().markerLayer.id = 'markerLayer';
                        };
                        myoverlay.setMap(map);

                        //done
                        
                    }
                    
                }
                
            }
        };
    });
});
