$(document).ready(function () {

    

    var bindBlogGE = {
        $domElements: null,
        init: function () {
            this.$domElements = $(".blog-widget .items");
            var self = this;

            if (self.$domElements.length > 0) {
                $(window).on('load resize orientationchange', function () {
                    self._bind();
                });
                self._bind();
            }
        },
        _matchHeight: function () {
            $(".blog-widget .text .post-summary").matchHeight();
            $(".blog-widget .text .post-title").matchHeight();
        },
        _bind: function () {
            var self = this;
            self.$domElements.each(function () {

                var $slickJs = $(this);
                if ($(window).width() >= 768) {
                    if ($slickJs.hasClass('slick-initialized')) {
                        $slickJs.slick('unslick');
                    }
                    self._matchHeight();
                }
                else {
                    if (!$slickJs.hasClass('slick-initialized')) {
                        $slickJs.on('init', function (e, slick) {
                            self._matchHeight();
                        });
                        $slickJs.slick({
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            mobileFirst: true,
                            infinite: false,
                            arrows: false
                            //centerMode: true,
                        });
                    }
                }
            });
        }

    };
    bindBlogGE.init();

});