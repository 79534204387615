$(document).ready(function () {
    $(".locationsPage").each(function () {
        var $thisPageDiv = $(this);
        
        
        var LocationsPageController = {
            Init: function () {
                var self = this;
                self.bindTabs();
            },
            bindTabs: function () {
                var self = this;

                //first duplicate tabs for mobile tab rendering.
                $('#detail-tabs .tab-buttons > button').each(function () {
                    var $mobileButton = $(this).clone();
                    $mobileButton.attr("id", $mobileButton.attr("id") + "-mobile").addClass("mobile-only");
                    $($mobileButton.attr("data-target")).before($mobileButton);
                });


                $('#detail-tabs .tab-buttons > button').on('click', function (e) {
                    var $thisButton = $(this);
                    if (!$thisButton.hasClass("collapsed")) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                    
                });
                $('#detail-tabs .btn-tab.mobile-only').click(function (e) {
                    var $thisButton = $(this);
                    var originalScrollAmount = $(window).scrollTop();
                    //scroll
                    setTimeout(function () {
                        var scrollAmount = originalScrollAmount; //window.GRGExtensions.scrollToElement($thisButton, { headerHeight: $("header").height(), justCalculate: true });
                        //console.log(scrollAmount);
                        if (scrollAmount > 1 || scrollAmount < 1) {
                            //window.GRGExtensions.scrollToElement($("#detail-tabs"), { speed: 0 });
                            $(window).scrollTop(originalScrollAmount);
                            if (scrollAmount < 0) {
                                //
                                window.GRGExtensions.scrollToElement($thisButton);
                            }
                            else {
                                window.GRGExtensions.scrollToElement($thisButton);
                            }
                        }
                    }, 400);
                });
            }
        };

        LocationsPageController.Init();

    });
});
