$(document).ready(function () {
    $(".footer-quote-grid-editor .quote-slider").each(function () {
        var total = $(this).children().length; // get the number of slides
        var rand = Math.floor(Math.random() * total); // random number
        $(this).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            infinite: true,
            arrows: true,
            accessibility: false
            //centerMode: true,
        }).slick('slickGoTo', rand, true); //navigate to random slide
    });
});
