$(document).ready(function () {
    //sections
    $(".subpage.faqPage").each(function () {
        var firstRun = true;
        var $form = $("form.search");
        var $tabsContentHeader = $(".faqs-content .tabs-content .header");
        var $tabsSidebar = $(".faqs-content .sidebar");
        var $tabsSidebarHeaders = $tabsSidebar.find(".tab-headers");
        var $searchContent = $form.find(".search-content");
        var $mostPopularQuestions = $("form .most-popular-questions");
        var $mobileTarget = $("#mobile-content");
        var scrollPadding = 10;
        var $mainContent = $(".faqs-content .main-content");
        var scrollTimeout = null;

        buildMobileContent();//build me an army
        eventsMobileContent();

        //tabs based on nav-tabs href and tab pane id
        //listen to hash change
        $(window).on('hashchange', function (e) {
            e.preventDefault();
            setActiveTab();
        });
        setActiveTab();

        //scrollable
        $(window).on('resize', function () {
            //setContentHeight($form, $tabsContentHeader, $tabsSidebarHeaders);
        });
        //setContentHeight($form, $tabsContentHeader, $tabsSidebarHeaders);

        //listen to event: 
        window.GRGEvents.SIG_FAQSearchResultClicked.add(function () {
            setActiveTab(true);
        });

        $tabsSidebarHeaders.find("a.tab-link").click(function (e) {
            e.preventDefault();
            window.location.hash = $(this).attr("href");
        });
        //
        $tabsSidebar.find("a.tab-link-all").click(function (e) {
            e.preventDefault();
            window.location.hash = "";
        });

        function buildMobileContent() {
            $tabsSidebarHeaders.children(".section").each(function () {
                //locate corresponding content
                var hashValue = $(this).children("a").attr("href").replaceAll("/", "").replaceAll("#", "");
                var $source = $("#tab-" + hashValue);
                var activeClass = "";
                if ($(this).hasClass("active")) {
                    activeClass = " active";
                }
                var html = $(this).clone().html();
                html = html.replaceAll("id=\"", "id=\"mobile-");
                $mobileTarget.append("<div class=\"tab-section" + activeClass + "\" id=\"mobile-section-" + hashValue + "\"><div class=\"tab-header\"><div class=\"section\">" + html + "</div></div><div class=\"mobile-tabs-content\"><div class=\"inner-content\">" + $source.clone().html().replaceAll("\"tab-", "\"mobile-tab-").replaceAll("\"section-tab", "\"mobile-section-tab").replaceAll("data-parent=\"#tab", "data-parent=\"#mobile-tab").replaceAll("data-target=\"#tab", "data-target=\"#mobile-tab") + "</div></div></div>");

            });
        }
        function eventsMobileContent() {
            $mobileTarget.find("a.tab-link").click(function (e) {
                e.preventDefault();
                //if currently open, close. otherwise, open. do nothing else
                var $currentMobileTab = $(this).closest(".tab-section");
                if ($currentMobileTab.hasClass("open")) {
                    //close
                    closeMobileActiveTabHeight($currentMobileTab);
                    $currentMobileTab.removeClass("open");

                } else {
                    closeAllMobileActiveTabHeight();
                    setMobileActiveTabHeight($currentMobileTab);
                    $currentMobileTab.addClass("open");
                    window.location.hash = $(this).attr("href");
                    //scroll to
                    scrollTimeout = setTimeout(function () {
                        window.GRGExtensions.scrollToElementTop($currentMobileTab);
                    }, 90);
                }


            });
            //accordions
            $mobileTarget.find(".accordion .mobile-tab-header button").click(function () {
                setTimeout(function () {
                    //click event, resize
                    recalculateMobileActiveTabHeight();
                }, 100);
                setTimeout(function () {
                    //click event, resize
                    recalculateMobileActiveTabHeight();
                }, 200);
                setTimeout(function () {
                    //click event, resize
                    recalculateMobileActiveTabHeight();
                }, 300);

            });
            $(window).resize(function () {
                recalculateMobileActiveTabHeight();
            });
        }
        function setAllMode() {
            var $navTabs = $(".tab-headers");
            var $navContent = $(".tabs-content .section");
            var $mobileContent = $("#mobile-content");

            $mobileContent.children(".tab-section").removeClass("active");
            $navTabs.children(".section").removeClass("active");
            $navContent.addClass("active");
            $navContent.find(".accordion .collapse").removeClass("show");
            $mainContent.addClass("all-mode");
            $mainContent.find(".current-category .other-headers div").removeClass("active");
        }
        function setCurrentCategoryHeader(id) {
            $mainContent.find(".current-category .other-headers div").removeClass("active");
            $(id).addClass("active");
        }
        function setActiveTab(searchResultClicked) {
            searchResultClicked = searchResultClicked || false;

            var $mobileContent = $("#mobile-content");
            var hash = window.location.hash;
            if (hash !== null && hash !== "") {
                var hashSectionLevel = hash;
                var hashTabLevel = "";
                
                var hashSplit = hash.split("/");
                if (hashSplit.length > 2) {
                    hashSectionLevel = hashSplit[0] + "/" + hashSplit[1] + "/";
                    hashTabLevel = hashSplit[2];
                }
                
                var hashValue = hashSectionLevel.replaceAll("/", "").replaceAll("#", "");
                //locate id in tabs
                var $navTabs = $(".tab-headers");
                var $navContent = $(".tabs-content .section");
                var offsetHeader = $form.find(".header").outerHeight();

                $mainContent.removeClass("all-mode");
                setCurrentCategoryHeader("#category-header-tab-" + hashValue);
                

                $mobileContent.children(".tab-section").removeClass("active");
                $navTabs.children(".section").removeClass("active");

                $navContent.removeClass("active");
                $("#tab-" + hashValue).addClass("active");


                //and the top nav tabs:
                $navTabActive = $navTabs.find("a[href=\"" + hashSectionLevel + "\"]");
                if ($navTabActive.length > 0) {
                    $navTabActive.closest(".section").addClass("active");
                }
                var $activeMobileTabSection = $("#mobile-section-" + hashValue).closest(".tab-section");
                $activeMobileTabSection.addClass("active");


                setTimeout(function () {
                    //unsure if open / close should be disconnected from active
                    $activeMobileTabSection.addClass("open");
                    setMobileActiveTabHeight($activeMobileTabSection);
                }, 50);

                //console.log("hashing: " + hashSectionLevel + " - " + hashTabLevel);
                

                if (hashTabLevel !== "") {
                    //open tab, scroll to it
                    var $tab = $("#section-" + hashTabLevel);

                    $tab.find("#" + hashTabLevel + "-content").addClass("show").removeClass("collapsed");
                    $form.find("#faq-search").val("");
                    $form.removeClass("autocomplete-open");
                    $form.find(".autocomplete-results .inner-content").remove();
                    
                    var $mobileQuestion = $activeMobileTabSection.find("#mobile-" + hashTabLevel + "-content");
                    var $mobileQuestionHeader = $activeMobileTabSection.find("#mobile-" + hashTabLevel + "-header-tab");

                    if (searchResultClicked) {
                        if ($(window).width() < 991) {
                            //expand mobile question
                            $mobileQuestion.addClass("show").removeClass("collapsed");
                            $mobileQuestionHeader.find(".btn").removeClass("collapsed");
                            if (scrollTimeout !== null) {
                                clearTimeout(scrollTimeout);
                            }
                            scrollTimeout = setTimeout(function () {
                                $('html, body').animate({
                                    scrollTop: $mobileQuestionHeader.offset().top - (10 + $("header").outerHeight())
                                }, 500);
                            }, 40);
                        } else {
                            //desktop
                            scrollTimeout = setTimeout(function () {
                                $('html, body').animate({
                                    scrollTop: $tab.offset().top - (10 + $("header").outerHeight())
                                }, 500);
                            }, 50);
                        }
                    }
                    else {
                        //not search result clicked
                        //scroll mobile
                        if ($(window).width() < 991) {

                            //expand mobile question
                            $mobileQuestion.addClass("show").removeClass("collapsed");
                            $mobileQuestionHeader.find(".btn").removeClass("collapsed");

                            /*scrollTimeout = setTimeout(function () {
                                $('html, body').animate({
                                    scrollTop: $mobileQuestionHeader.offset().top - (10 + $("header").outerHeight())
                                }, 500);
                            }, 50);*/
                        }
                    }
                    
                }
                else {
                    //only section swiping
                    //if autocomplete open, wipe it out
                    $form.removeClass("autocomplete-open");
                    $form.find("#faq-search").val("");

                }
                if ($(window).width() >= 991) {
                    window.scrollTo(0, 0);
                    scrollTimeout = setTimeout(function () {
                        window.scrollTo(0, 0);
                    }, 1);
                }
                window.GRGEvents.SIG_FAQSearchAutocompleteOpen.dispatch();
            }
            else {
                setAllMode();
            }
            firstRun = false;
            //remove loading
            $("body").addClass("loaded");
        }
        function recalculateMobileActiveTabHeight() {
            //ok
            var $activeMobileTabSection = $mobileTarget.children(".tab-section.active");
            //set this height
            var totalHeight = 0;
            $activeMobileTabSection.children(".mobile-tabs-content").children().each(function () {
                totalHeight += $(this).outerHeight();
            });
            $activeMobileTabSection.children(".mobile-tabs-content").css("height", totalHeight + "px");
        }
        function setMobileActiveTabHeight($activeMobileTabSection) {
            //ok
            //$mobileContent.find(".mobile-tabs-content").css("height", "");

            //set this height
            var totalHeight = 0;
            $activeMobileTabSection.children(".mobile-tabs-content").children().each(function () {
                totalHeight += $(this).outerHeight();
            });
            $activeMobileTabSection.children(".mobile-tabs-content").css("height", totalHeight + "px");
        }
        function closeMobileActiveTabHeight($activeMobileTabSection) {
            $activeMobileTabSection.children(".mobile-tabs-content").css("height", "");
        }
        function closeAllMobileActiveTabHeight() {
            $mobileTarget.find(".tab-section").removeClass("open");
            $mobileTarget.find(".mobile-tabs-content").css("height", "");
        }
        function scrollToTopIfMobile() {
            var hash = window.location.hash;
            if (hash !== null && hash !== "") {
                var offsetNavHeader = $("header").outerHeight();
                setTimeout(function () {
                    if ($(window).width() < 991) {
                        $('html, body').animate({
                            scrollTop: $(".tabs-content.actual-content").offset().top - offsetNavHeader
                        }, 500);

                    } else {
                        //scroll to all content
                        $('html, body').animate({
                            scrollTop: $(".faqs-content").offset().top - offsetNavHeader - 10
                        }, 500);
                    }
                }, 0);
            }
        }
       
    });

});

