(function ($) {
    $mobileOverlay = $(".mobile-overlay");
    $navbarToggler = $("#mobileNavbarToggler");
    $mobilemenuWrapper = $("#menu");
    $mobilemenuWrapperClose = $mobilemenuWrapper.find(".close");
    $mobileSearchToggler = $(".mobile-search-toggler");
    $mobileSearchDropdown = $(".mobile-search-dropdown");
    var srDelayTimeout = null;
    var searchAnimateTimeout = null;
    var navbarTogglerController = {
        bind: function () {
            var self = this;
            $navbarToggler.click(function (e) {
                e.preventDefault();
                if (!$(this).hasClass("open")) {
                    //$(this).addClass("open");
                    //$mobilemenuWrapper.addClass("open");
                    self.open();

                } else {
                    //$(this).removeClass("open");
                    //$mobilemenuWrapper.removeClass("open");
                    self.close();
                }
            });
            $mobilemenuWrapperClose.click(function (e) {
                e.preventDefault();
                self.close();
            });

            //ul li accordion
            $mobilemenuWrapper.find("ul > li.has-dropdown > a").click(function (e) {
                e.preventDefault();
                var $closestLi = $(this).closest("li");

                var $ulChild = $closestLi.children("ul");
                var totalHeight = 0;
                $ulChild.children("li").each(function () {
                    totalHeight += $(this).outerHeight();
                });
                totalHeight += 10;
                if (!$closestLi.hasClass("open")) {
                    $closestLi.addClass("open");
                    $closestLi.closest('ul').height("auto");
                    $closestLi.children("ul").height(totalHeight + "px");
                }
                else {
                    $closestLi.removeClass("open");
                    $closestLi.closest('ul').height("auto");
                    $closestLi.children("ul").height("1px");
                    $closestLi.find("ul > li.has-dropdown.open").each(function () {
                        $(this).removeClass("open");
                        $(this).children("ul").height("1px");
                    });
                }
            });
            //resize accordions
            $(window).resize(function () {
                $mobilemenuWrapper.find("ul > li.has-dropdown").each(function () {
                    var $closestLi = $(this);

                    if ($closestLi.hasClass("open")) {
                        var $ulChild = $closestLi.children("ul");
                        var totalHeight = 0;
                        $ulChild.children("li").each(function () {
                            totalHeight += $(this).outerHeight();
                        });
                        totalHeight += 10;
                        if (!$closestLi.hasClass("open")) {
                            $closestLi.addClass("open");
                            $closestLi.closest('ul').height("auto");
                            $closestLi.children("ul").height(totalHeight + "px");
                        }
                    }
                });
            });
        },
        open: function () {
            mobileSearchTogglerController.close();
            $navbarToggler.addClass("open");
            $mobilemenuWrapper.addClass("open");
            if (srDelayTimeout !== null) {
                clearTimeout(srDelayTimeout);
            }
            $mobilemenuWrapper.removeClass("sr-none");
        },
        close: function () {
            $navbarToggler.removeClass("open");
            $mobilemenuWrapper.removeClass("open");
            srDelayTimeout = setTimeout(function () {
                $mobilemenuWrapper.addClass("sr-none");
            }, 400);
            
        }
    };
    
    var mobileSearchTogglerController = {
        bind: function () {
            var self = this;
            $mobileSearchToggler.click(function (e) {
                e.preventDefault();

                //close
                navbarTogglerController.close();

                if (!$(this).hasClass("open")) {
                    //$(this).addClass("open");
                    //$mobileSearchDropdown.addClass("open");
                    self.open();
                } else {
                    //$(this).removeClass("open");
                    //$mobileSearchDropdown.removeClass("open");
                    self.close();
                }

            });
        },
        open: function () {
            $mobileSearchToggler.addClass("open");
            $mobileSearchDropdown.addClass("open");
            if (!searchAnimateTimeout === null) {
                clearTimeout(searchAnimateTimeout);
            }
            searchAnimateTimeout = setTimeout(function () {
                $mobileSearchDropdown.addClass("in");
            }, 20);
        },
        close: function () {
            $mobileSearchToggler.removeClass("open");
            $mobileSearchDropdown.removeClass("in");
            if (!searchAnimateTimeout === null) {
                clearTimeout(searchAnimateTimeout);
            }
            searchAnimateTimeout = setTimeout(function () {
                $mobileSearchDropdown.removeClass("open");
            }, 300);
        }
    };

    navbarTogglerController.bind();
    mobileSearchTogglerController.bind();

    /*
    var menuTimer, $mobilemenuWrapper, $multiselectMobile, $mobileOverlay, $mobilemenu, $navbarToggler, $navbarTogglerHtml
    var touchMoveScrollSwitch = true;
    var focusOnOpen = false;
    $(function () {
        jQuery.extend(verge);
        //Set Cached selectors
        $mobilemenuWrapper = $("#menu");
        $multiselectMobile = $("#menu", "#menu_multilevelpushmenu");
        $mobileOverlay = $(".mobile-overlay");
        $navbarToggler = $("#mobileNavbarToggler");
        //$navbarSearchToggler = $("#mobileNavbarSearchToggler");
        $mobileSearchInput = $("#mobileSearchInput");

        //Instantiate mobile menu object into cover mode with HTML implementation
        $mobilemenuWrapper.multilevelpushmenu({
            mode: 'cover',
            fullCollapse: true,
            backItemIcon: 'fa fa-caret-left',
            groupIcon: 'fa fa-caret-right',
            menuWidth: '100%',
            menuHeight: '100%',
            collapsed: true,
            onMenuReady: function () {
                $mobilemenu = $("#menu_multilevelpushmenu");
                $mobilemenuWrapper.removeClass('domReady');
            },
            onCollapseMenuStart: function () {
                if ($mobilemenuWrapper.multilevelpushmenu('activemenu').attr('data-level') === "0") {
                    $mobileOverlay.addClass('out');
                }
            },
            onCollapseMenuEnd: function () {
                if (!$mobilemenuWrapper.multilevelpushmenu('activemenu').length) {
                    $('html').removeClass('freeze');
                    $('body').removeClass('freeze');
                    setTimeout(function () {
                        $mobileOverlay.removeClass('show').removeClass('out');
                    }, 100);
                }
                ScrollMobileMenu($mobilemenuWrapper.multilevelpushmenu('activemenu'));
            },
            onExpandMenuStart: function () {
                $mobileOverlay.addClass('show');
                $('html').addClass('freeze');
                $('body').addClass('freeze');
                //Fix a bug where the menu doesn't open up to be full-width
                $mobilemenuWrapper.multilevelpushmenu('redraw');
            },
            onExpandMenuEnd: function () {
                ScrollMobileMenu($mobilemenuWrapper.multilevelpushmenu('activemenu'));
                if (focusOnOpen !== false) {
                    $(focusOnOpen).focus();
                    focusOnOpen = false;
                }
            },
            onMenuSwipe: function () {
                //Prevent menu swipe event from stealing focus from form elements
                return false;
            },
            onItemClick: function () {
                //Manually send the user to a link provided it has a valid href
                var event = arguments[0];
                //console.log('changed touches ' + $(arguments[0].originalEvent.changedTouches));
                if (touchMoveScrollSwitch) {
                    var $anchor = $(event.target);
                    var url = $anchor.attr('href');
                    var userTarget = $anchor.attr('target');
                    var smartTarget = url.startsWith("/") || url.startsWith("tel:") || url.startsWith("mailto:") ? "_self" : "_blank";
                    var target = userTarget || smartTarget;

                    if (url.length) {
                        window.open(url, target);
                    }
                }
            }
        });

        var touchTimer;
        $('.levelHolderClass').on('scroll', function (e) {
            touchMoveScrollSwitch = false;
            clearTimeout(touchTimer);
            touchTimer = setTimeout(function () {
                touchMoveScrollSwitch = true;
            }, 200);
        });

        //Redraw the mobile menu to enable responsiveness
        $(window).on('resize', function () {
            //Only run resize function every 50ms while in the correct viewport to lower overhead of client function
            if (isViewportInMobile()) {
                clearTimeout(menuTimer);
                menuTimer = setTimeout(function () {
                    $mobilemenuWrapper.multilevelpushmenu('redraw');
                    ScrollMobileMenu($mobilemenuWrapper.multilevelpushmenu('activemenu'));
                }, 50);
                collapseMenu();
            }
        });

        $navbarToggler.on('click', function () {
            // Toggle display of mobile menu and transform hamburger into close button
            toggleMenuClick();
        });

        function toggleMenuClick() {
            if ($mobilemenuWrapper.multilevelpushmenu('activemenu').length === 0) {
                expandMenu();
            }
            else {
                collapseMenu();
            }
        }

        function expandMenu() {
            $navbarTogglerHtml = $navbarToggler.html();
            $mobilemenuWrapper.multilevelpushmenu('expand');
            $navbarToggler.html("X").addClass("closeLabel");
        }

        function collapseMenu() {
            $mobilemenuWrapper.multilevelpushmenu('collapse');
            $navbarToggler.removeClass("closeLabel").html($navbarTogglerHtml);
        }

        function ScrollMobileMenu($element) {
            if (!$.inViewport($element.find('>ul>li:last-child'))) {
                $element.addClass('scrolled');
            }
            else {
                $element.removeClass('scrolled');
            }
        }

        $($mobileOverlay).click(function (e) {
            collapseMenu();
        });

        //Workaround what I believe to be a stacking context issue - header logo is clickable behind mobile menu search form
        $(".nav-wrapper").click(function (e) {
            if ($mobilemenuWrapper.multilevelpushmenu('activemenu').length !== 0) return false;
        });
    });

    function isViewportInMobile(mobileWidthOverride) {
        var widthToCheckAgainst = mobileWidthOverride || 768;
        return window.innerWidth < widthToCheckAgainst;
    }*/
})(jQuery);