$(document).ready(function () {
    //sections
    $(".subpage.faqPage").each(function () {
        //search interaction
        var $form = $("form.search");
        var $header = $("form.search .header");
        var $inputField = $header.find("#faq-search");
        var noResultsMessage = "No results were found.";

        $header.hover(function () {
            $header.addClass("active");
        }, function () {
            $header.removeClass("active");
        });

        $inputField.on("keyup", function (e) {
            var searchedVal = $inputField.val();
            var searchResults = "";
            if (searchedVal.length > 1) {
                //search across for this field
                var $activeSection = $(".tabs-content .section.active");
                if ($activeSection.length > 0) {
                    searchResults = searchFaqs(searchedVal, $activeSection.attr("id"));
                }

            }
            $(".autocomplete-results").html(searchResults);
            window.GRGEvents.SIG_FAQSearchAutocompleteOpen.dispatch();
            //bind clicks

            $form.find(".autocomplete-results a.result").click(function (e) {
                e.preventDefault();
                var href = $(this).attr("href");
                //manually fire
                //console.log("compare " + window.location.hash + " vs " + href);
                window.location.hash = href;
                window.GRGEvents.SIG_FAQSearchResultClicked.dispatch();

        /*      if (window.location.hash === href) {
                    //setActiveTab();
                    //manual fire: 
                    //console.log("manual fire");
                    window.GRGEvents.SIG_FAQSearchResultClicked.dispatch();
                }
        */
            });

            if (searchResults !== "") {
                //$(".autocomplete-results").html(searchResults);
            } else {
                if (searchedVal.length > 1) {
                    //none 
                    $(".autocomplete-results").html("<div class=\"inner-content\"><div class=\"result\">" + noResultsMessage + "</div></div>");
                }
            }

        });
        $inputField.on("focus", function () {
            $form.addClass("autocomplete-open");
            $form.find(".search-content").scrollTop(0);
            window.GRGEvents.SIG_FAQSearchAutocompleteOpen.dispatch();
        });
        //click away
        $("body").click(function (e) {
            if ($(e.target).closest("form.search").length === 0) {
                $form.removeClass("autocomplete-open");
                window.GRGEvents.SIG_FAQSearchAutocompleteOpen.dispatch();
            }
        });

        function searchFaqs(term, currentID) {
            term = term.toLowerCase();
            //console.log(term);
            //console.log(faqsModel);
            //console.log(currentID);
            //logic is:
            //locate current section
            //search title
            var searchResults = "";
            for (var i = 0; i < faqsModel.faqSections.length; i++) {
                //if (faqsModel[i].sectionId == currentID) {
                //console.log("search " + faqsModel.faqSections[i].sectionId);

                //search tab titles
                for (var j = 0; j < faqsModel.faqSections[i].items.length; j++) {
                    {
                        if (faqsModel.faqSections[i].items[j].title.toLowerCase().indexOf(term) > -1) {
                            var searchedField = faqsModel.faqSections[i].items[j].title;
                            var termLength = term.length;
                            var startIndex = faqsModel.faqSections[i].items[j].title.toLowerCase().indexOf(term);

                            var highlighted = searchedField.substr(0, startIndex) + "<span class=\"highlighted\">" +
                                searchedField.substr(startIndex, termLength) + "</span>" +
                                searchedField.substr(startIndex + termLength);

                            searchResults += "<a class=\"result\" href=\"" + faqsModel.faqSections[i].sectionUrl + "" + faqsModel.faqSections[i].items[j].tabId + "/\">" + highlighted + "</a>";
                        }
                    }
                }
                //}
            }
            if (searchResults !== "") {
                searchResults = "<div class=\"inner-content\"><div>" + searchResults + "</div></div>";
            }
            return searchResults;
        }

        $form.submit(function (e) {
            e.preventDefault();
        });


    });
});

