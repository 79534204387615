$(document).ready(function () {
    $(".searchLocationLandingPage, .searchNoLocationLandingPage, .subpage.cityLocation").each(function () {
        //find and populate destination field
        var $destinationLabel = $(".umbraco-forms-label:contains(" + "Destination" + ")");
        if ($destinationLabel.length > 0) {
            var $input = $destinationLabel.closest(".umbraco-forms-field").find("input[type=\"text\"]");
            $input.val(searchedDestination);
        }
    });
});
