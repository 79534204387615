function TagIEVersion() {
    var rv = -1; // Return value assumes failure.

    if (navigator.appName === 'Microsoft Internet Explorer') {

        var ua = navigator.userAgent,
            re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");

        if (re.exec(ua) !== null) {
            rv = parseFloat(RegExp.$1);
        }
    }
    else if (navigator.appName === "Netscape") {
        /// in IE 11 the navigator.appVersion says 'trident'
        /// in Edge the navigator.appVersion does not say trident
        if (navigator.appVersion.indexOf('Trident') === -1) {
            //
        }
        else {
            rv = 11;
        }
    }
    if (rv > -1) {
        var root = document.documentElement;
        if (root.className === "") {
            root.className += 'ie';
        }
        else {
            root.className += ' ie';
        }
    }
    return rv;
}
