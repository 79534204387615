// Daterange picker, Aaron Sawyer, Marathon Consulting, 2019.
// using
// jQuery Plugin Boilerplate
// A boilerplate for jumpstarting jQuery plugins development
// version 2.0, July 8th, 2011
// by Stefan Gabos

//call like so:
//values go into each input
// $.daterange($(".date-inline-target"), { startDateDom: $startDateDom, endDateDom: $endDateDom });
// or, values go aggregated into one
// $.daterange((".date-inline-target"), { dateDom: $dateDom });
//popup mode
//$.daterange(null, { startDateDom: $startDateDom, endDateDom: $endDateDom });
//can also pass an onSelect callback in the options object.
; (function ($) {

    // we need attach the plugin to jQuery's namespace or otherwise it would not be
    // available outside this function's scope
    // "el" should be a jQuery object or a collection of jQuery objects as returned by
    // jQuery's selector engine
    $.daterange = function (el, options) {

        // to avoid confusions, use "plugin" to reference the
        // current instance of the  object
        var plugin = this;
        plugin.values = {
            startDate: "",
            endDate: ""
        };

        // plugin's default options
        // this is private property and is accessible only from inside the plugin
        var defaults = {

            startDateDom: null,
            endDateDom: null,
            dateDom: null,
            startHiddenField: null,
            endHiddenField: null,
            onSelect: null,
            beforeShowDay: null,
            getStartValue: function () { return plugin.values.startDate; },
            getEndValue: function () { return plugin.values.endDate; },
            startDate: "",
            endDate: "",
            changeYear: false,
            numberOfMonths: 1,
            closeSelectedDelay: 0, //if not keepOpen, ms to close
            minDate: null, //min start date
            renderClearButton: true

            // if your plugin is event-driven, you may provide callback capabilities 
            // for its events. call these functions before or after events of your 
            // plugin, so that users may "hook" custom functions to those particular 
            // events without altering the plugin's code
            //onSomeEvent: function () { }

        }

        

        // this will hold the merged default, and user-provided options
        // plugin's properties will be accessible like:
        // plugin.settings.propertyName from inside the plugin or
        // myplugin.settings.propertyName from outside the plugin
        // where "myplugin" is an instance of the plugin
        plugin.settings = {};
        
        // the "constructor" method that gets called when the object is created
        // this is a private method, it can be called only from inside the plugin
        var init = function () {

            // the plugin's final properties are the merged default and 
            // user-provided options (if any)
            plugin.settings = $.extend({}, defaults, options);

            //hardcoded vars
            plugin.settings.inlineMode = true;
            plugin.settings.mode = "double";
            plugin.settings.$targetDatepicker = null;

            // make the collection of target elements available throughout the plugin
            // by making it a public property
            plugin.el = el;
            
            //code here
            if (plugin.el !== null && plugin.el.length > 0) {
                //inline
                plugin.settings.$targetDatepicker = plugin.el;
                plugin.settings.inlineMode = true;
            }
            else {
                //popup
                plugin.settings.inlineMode = false;
                if (plugin.settings.startDateDom !== null) {
                    plugin.settings.$targetDatepicker = plugin.settings.startDateDom;
                }
                else {
                    if (plugin.settings.dateDom !== null) {
                        plugin.settings.$targetDatepicker = plugin.settings.dateDom;
                        plugin.settings.mode = "single";
                    }
                    else {
                        console.log("Neither startDateDom or dateDom are set, please set either one in settings object");
                    }

                }
            }
            if (plugin.settings.startDate !== "") {
                plugin.values.startDate = plugin.settings.startDate
            }
            if (plugin.settings.endDate !== "") {
                plugin.values.endDate = plugin.settings.endDate
            }
            if (plugin.settings.changeYear !== null && plugin.settings.changeYear == true) {
                plugin.values.changeYear = true;
            }
            if (typeof plugin.settings.numberOfMonths !== 'undefined' && plugin.settings.numberOfMonths !== null) {
                plugin.values.numberOfMonths = plugin.settings.numberOfMonths;
            }
            if (typeof plugin.settings.closeSelectedDelay !== null) {
                plugin.values.closeSelectedDelay = plugin.settings.closeSelectedDelay;
            }
            if (typeof plugin.settings.renderClearButton !== null) {
                plugin.values.renderClearButton = plugin.settings.renderClearButton;
            }
            
            //min start date
            if (plugin.settings.minDate !== null) {
                plugin.values.minDate = plugin.settings.minDate;
            }
            else {
                plugin.values.minDate = getTodaysDate();
            }

            plugin.settings.$targetDatepicker.datepicker({
                minDate: plugin.values.minDate,
                changeYear: plugin.values.changeYear,
                numberOfMonths: plugin.values.numberOfMonths,
                onSelect: function (dateText, inst) {
                    var selectedDateObject = getDateFromString(dateText);
                    var keepOpen = false; //stay open
                    var closingPopup = false; //close with delay
                    //console.log("plugin.values.startDate", plugin.values.startDate);
                    //console.log("plugin.values.endDate", plugin.values.endDate);

                    if (plugin.values.startDate == "" && plugin.values.endDate == "") {
                        plugin.values.startDate = dateText;
                        if (!plugin.settings.inlineMode) {
                            keepOpen = true;
                        }
                    }
                    else if (plugin.values.endDate == "") {
                        if (selectedDateObject < getDateFromString(plugin.values.startDate)) { //date before start?
                            //reset, new start
                            plugin.values.startDate = dateText;
                            if (!plugin.settings.inlineMode){
                                keepOpen = true;
                            }
                        }
                        else {
                            plugin.values.endDate = dateText;
                            //stop users from selecting too far ahead a start date?
                            if (plugin.values.closeSelectedDelay > 0) {
                                if (!plugin.settings.inlineMode) {
                                    inst.inline = true;
                                }
                            }
                        }
                    }
                    else {
                        plugin.values.startDate = dateText;
                        plugin.values.endDate = "";
                        if (!plugin.settings.inlineMode) {
                            keepOpen = true;
                        }
                        //stop users from selecting too far ahead a start date?
                    }
                    
                    //set values of input fields
                    if (plugin.settings.mode == "double") {
                        if (plugin.values.startDate !== "") {
                            plugin.settings.startDateDom.val(plugin.values.startDate);
                        }
                        else {
                            plugin.settings.startDateDom.val("");
                        }
                        if (plugin.values.endDate !== "") {
                            plugin.settings.endDateDom.val(plugin.values.endDate);
                        } else {
                            plugin.settings.endDateDom.val("");
                        }
                        plugin.settings.startDateDom.blur();
                        plugin.settings.endDateDom.blur();
                    }
                    else {
                        //single, aggregate values as d1 - d2
                        if (plugin.values.startDate !== "") {
                            plugin.settings.dateDom.val(plugin.values.startDate);
                        }
                        else {
                            plugin.settings.dateDom.val("");
                        }
                        if (plugin.values.endDate !== "") {
                            plugin.settings.dateDom.val(plugin.values.startDate + " - " + plugin.values.endDate);
                        }
                        plugin.settings.dateDom.blur();
                    }
                    
                    if (keepOpen) {
                        inst.inline = true;
                    }
                    else {
                        if (!plugin.settings.inlineMode) {
                            if (plugin.values.closeSelectedDelay > 0) {
                                closingPopup = true;
                            }
                            else {
                                inst.inline = false; //just close now
                            }
                        }
                    }
                    //console.log("closingPopup: " + closingPopup + ". keepOpen: " + keepOpen);
                    //close datepicker popup with delay, if a delay is set and not inline picker
                    if (closingPopup) {
                        setTimeout(function () {
                            inst.inline = false;
                            $('#' + inst.id).datepicker('hide');
                            if (plugin.settings.mode == "double") {
                                plugin.settings.startDateDom.blur();
                                plugin.settings.endDateDom.blur();
                            }
                            else {
                                plugin.settings.dateDom.blur();
                            }
                        }, plugin.values.closeSelectedDelay);
                    } 

                    if (plugin.settings.onSelect !== null) {
                        plugin.settings.onSelect();
                    }
                    
                },
                onClose: function () {
                    if (!plugin.settings.inlineMode) {
                        $(this).data('datepicker').inline = false;
                    }
                },
                beforeShowDay: function (date) {
                    var year = date.getFullYear();
                    // months and days are inserted into the array in the form, e.g "01/01/2009", but here the format is "1/1/2009"
                    var month = padNumber(date.getMonth() + 1);
                    var day = padNumber(date.getDate());
                    // This depends on the datepicker's date format
                    var dateString = month + "/" + day + "/" + year;
                    var returnVal = [true, ""];
                    
                    //have parsed date we are rendering
                    if (plugin.values.startDate !== "" && plugin.values.endDate !== "") {
                        //two values! render
                        var startDateObject = getDateFromString(plugin.values.startDate);
                        var endDateObject = getDateFromString(plugin.values.endDate);
                        if ((startDateObject !== null && date.getTime() == startDateObject.getTime()) || (endDateObject !== null && date.getTime() == endDateObject.getTime())) {
                            returnVal = [true, "ui-state-active-parent"];
                        } else
                        if (startDateObject !== null && endDateObject !== null && date.getTime() > startDateObject.getTime() && date.getTime() < endDateObject.getTime()) {
                            // Enable date so it can be deselected. Set style to be highlighted
                            returnVal = [true, "ui-state-highlight-parent"];
                        } else
                        if (dateString == getTodaysDate()){
                            returnVal = [true, "current-day-unselected"];
                        }
                    }
                    //unselect min date
                    var dateOnly = date.setHours(0, 0, 0, 0);
                    if (plugin.values.minDate !== null) {
                        var isMinUnselectedDay = false;
                        if (jQuery.type(plugin.values.minDate) === "date") {
                            var minDateOnly = plugin.values.minDate.setHours(0, 0, 0, 0);
                            if (plugin.values.startDate == "" && dateOnly === minDateOnly) {
                                isMinUnselectedDay = true;
                            }
                        } else if (jQuery.type(plugin.values.minDate) === "string") {
                            var minDateOnly = getDateFromString(plugin.values.minDate).setHours(0, 0, 0, 0);
                            if (plugin.values.startDate == "" && dateOnly === minDateOnly ) {
                                isMinUnselectedDay = true;
                            }
                        }
                        if (isMinUnselectedDay) {
                            returnVal[1] = "min-day-unselected";
                        }
                    }

                    //if renderClearButton
                    if (plugin.values.renderClearButton) {
                        //if dom el not there, create it
                        setTimeout(function () {
                            //console.log($(this));
                            var $currentDatepickerDom = $("#ui-datepicker-div");
                            if ($currentDatepickerDom.children(".clear-dates").length === 0) {
                                //create dom
                                $currentDatepickerDom.append("<button type=\"button\" class=\"clear clear-dates\">Clear</button>");
                                $currentDatepickerDom.children('button.clear-dates').click(function (e) {
                                    e.preventDefault();
                                    plugin.clearDates();
                                    if (plugin.settings.mode == "double") {
                                        plugin.settings.endDateDom.val("");
                                        plugin.settings.endDateDom.blur();
                                    }
                                });
                            }
                            //if ($this)
                        }, 0);
                    }
                   
                    //allow custom functions
                    //currently set to only disable if custom function disables, will not allow
                    if (plugin.settings.beforeShowDay !== null) {
                        var customReturnVal = plugin.settings.beforeShowDay(date);
                        //custom function returned invalid
                        if (typeof customReturnVal !== 'undefined') {
                            if (customReturnVal[0] == false) {
                                returnVal[0] = customReturnVal[0];
                            }
                        }
                        else {
                            //just execute, return nothing
                        }
                    }

                    // Dates not in the array are left enabled, but with no extra style
                    return returnVal;
                }
            });


            //event binding
            if (plugin.settings.startDateDom !== null) {
                plugin.settings.startDateDom.on("focus", function () {
                });
            }
            if (plugin.settings.endDateDom !== null) {
                plugin.settings.endDateDom.on("click", function () {
                    plugin.settings.$targetDatepicker.datepicker('show');
                    plugin.settings.endDateDom.focus();
                });
                plugin.settings.endDateDom.on("focus", function () {

                    //popup
                    /*if (plugin.settings.startDateDom !== null) {
                        plugin.settings.startDateDom.focus();
                    } else
                        if (plugin.settings.dateDom !== null) {
                            plugin.settings.dateDom.focus();
                        }
                    */
                    //plugin.settings.$targetDatepicker.datepicker('show');
                    //plugin.settings.endDateDom.focus();
                });
            }
            if (plugin.settings.dateDom !== null) {
                plugin.settings.dateDom.on("focus", function () {
                    //nothing now
                });
            }

            //go to month of start date, if has one
            if (plugin.settings.startDate !== "" && plugin.values.startDate !== "")
            {
                var startDateObject = getDateFromString(plugin.values.startDate);
                plugin.settings.$targetDatepicker.datepicker('setDate', startDateObject);
            }
        }

        // public methods
        // these methods can be called like:
        // plugin.methodName(arg1, arg2, ... argn) from inside the plugin or
        // myplugin.publicMethod(arg1, arg2, ... argn) from outside the plugin
        // where "myplugin" is an instance of the plugin

        // a public method.
        plugin.getStartValue = function () {

            // code goes here
            return plugin.values.startDate;
        };
        plugin.getEndValue = function () {

            // code goes here
            return plugin.values.endDate;
        }
        plugin.clearDates = function () {
            plugin.values.startDate = "";
            plugin.values.endDate = "";
            plugin.settings.$targetDatepicker.datepicker("setDate", null);
        };
        //set with selected
        plugin.setStartDate = function (input) {
            plugin.values.startDate = input;
        };
        plugin.setEndDate = function (input) {
            plugin.values.endDate = input;
        };
        plugin.setStartEndDates = function (start, end) {
            plugin.values.startDate = start;
            plugin.values.endDate = end;
            plugin.settings.$targetDatepicker.datepicker("setDate", start);
            if (plugin.settings.startDateDom !== null) {
                plugin.settings.startDateDom.val(start);
            }
            if (plugin.settings.endDateDom !== null) {
                plugin.settings.endDateDom.val(end);
            }
        };
        plugin.refreshDatepicker = function () {
            plugin.settings.$targetDatepicker.datepicker("refresh");
        }

        // private methods
        //date helpers
        var getTodaysDate = function () {
            var today = new Date();
            var dd = today.getDate();

            var mm = today.getMonth() + 1;
            var yyyy = today.getFullYear();
            if (dd < 10) {
                dd = '0' + dd;
            }

            if (mm < 10) {
                mm = '0' + mm;
            }
            today = mm + '/' + dd + '/' + yyyy;
            return today;
        };
        var padNumber = function (number) {
            var ret = new String(number);
            if (ret.length == 1) ret = "0" + ret;
            return ret;
        };
        var getDateFromString = function (date_string) {
            var myDate = null;
            //console.log("getting date from string: " + date_string);
            if (date_string != null && date_string !== "" && date_string.indexOf("/") > -1) {
                //split
                var parts = date_string.split('/');
                myDate = new Date(parts[2] * 1, parts[0] - 1, parts[1]);
            }
            return myDate;
        };
        var getDateStringFromDate = function (date_obj) {
            var returnString = "";
            if (date_obj != null) {
                var year = date_obj.getFullYear();
                // months and days are inserted into the array in the form, e.g "01/01/2009", but here the format is "1/1/2009"
                var month = padNumber(date_obj.getMonth() + 1);
                var day = padNumber(date_obj.getDate());
                returnString = month + "/" + day + "/" + year;
            }
            return returnString;
        };
        

        // call the "constructor" method
        init();

    }

})(jQuery);

