jQuery.extend(verge);
var timer;
var mobileHeaderDateFieldsController; //exist on every page
var desktopHeaderDateFieldsRange;
TagIEVersion();

$(document).ready(function () {
    BindPrimaryNavigation();

    //Initialize the clickable links
    Marathon.SetLinksAsClickable.OnReady();

    //skip content click
    var $skipContentLink = $(".skip-content");
    $skipContentLink.click(function (e) {
        e.preventDefault();
        window.GRGExtensions.scrollToElementTop($("#skip-destination"), $("header").height());
    });
    $skipContentLink.on("keydown", function (e) { // Trigger the click event from the keyboard
        var code = e.which;
        // 13 = Return, 32 = Space
        if ((code === 13) || (code === 32)) {
            $(this).click();
        }
    });

    //inline links
    $("main a[href*='#']").click(function (e) {
        var $thisLinkTarget = $(this).attr("href");
        if ($thisLinkTarget.indexOf("http://") < 0 && $thisLinkTarget.indexOf("https://") < 0) {
            e.preventDefault();
            var thisLinkTargetId = $thisLinkTarget.substr($thisLinkTarget.indexOf("#"));
            window.GRGExtensions.scrollToElementTop($(thisLinkTargetId), $("header").height() + 10);
        }
    });

    //matchheights
    $(".horizontal-cta-widget .buttons a.btn").matchHeight();
    $(".contact-us-blocks-grid-editor .flex-row .block .inner-content").matchHeight();
    $(".card .content-section").matchHeight();
    $(".banner-button-section .btn.banner-button").matchHeight();
    $(".buttons.btns-2 a").matchHeight();

    //search widgets
    $(".form-header-search, .form-banner-search, .form-banner-search-mobile, .form-mobile-header-search, .form-inline-search, .form-inline-search-mobile").each(function () {
        var $locationField = $(this).find(".text-location");
        var $stateField = $(this).find("select.location-state");
        var isMobileHeaderSearch = $(this).hasClass("form-mobile-header-search");
        var isMobileBannerSearch = $(this).hasClass("form-banner-search-mobile");
        var isMobileInlineSearch = $(this).hasClass("form-inline-search-mobile");
        
        var $currentForm = $(this);
        /*$currentForm.submit(function (e) {
            e.preventDefault();
        });*/
        $currentForm.validate({
            onsubmit: true,
            onfocusout: false,
            focusInvalid: false,
            invalidHandler: function (form, validator) {
                //alert("invalidHandler");
                setTimeout(function () {
                    $currentForm.find("label.error").fadeOut();
                }, 1500);
                return false;
            },
            rules: {
                "text-location": {
                    required: true,
                    mustContainState: true
                },
                "text-location-mobile": {
                    required: true,
                    mustContainState: true
                }
            },
            submitHandler: function (form, event) {
                event.preventDefault();
                //alert("submitting");
                //edit me to be locations
                var searchUrl = window.GRGExtensions.searchUrlHelper.rebuildUrlFriendlyLocationName($locationField.val());
                window.location.href = searchUrl;
                return false;
            }
        });
        /*$currentForm.find("input[type=\"text\"]").keypress(function (e) {
            if (e.keyCode === 13) {
                alert("caught 13");
                e.preventDefault();
            }
        });*/

        /*$currentForm.submit(function (e) {
            e.preventDefault();
        });*/
        

    });
    //autocomplete of location
    window.GRGExtensions.EventHelper.fireOnMapsLoaded(function () {
        window.GRGExtensions.BindGoogleAutocomplete();
    });


    //listen for google maps loaded, fire signal at completion
    if (google_map_loaded !== null && google_map_loaded === false) {
        var google_map_loaded_timer = setInterval(function () {
            //console.log("checking gmaps loop...");
            if (google_map_loaded) {
                clearInterval(google_map_loaded_timer);
                window.GRGEvents.SIG_GoogleMapsLoaded.dispatch();
            }
        }, 100);
    }

    //Kickoff banner size fit
    var $subpageBanners = $(".subpage-banner, .search-landing-banner");
    window.GRGExtensions.expandBannersWithForm($subpageBanners);
    $(window).on('resize', function () {
        window.GRGExtensions.expandBannersWithForm($subpageBanners);
    });
    
    /*$(window).on('resize', function () {
        clearTimeout(timer);
        timer = setTimeout(adjustMenu, 50);
    });*/
    $(".nav-utility li.round-button > a.nav-link").click(function (e) {
        e.preventDefault();
    });
    $(".nav-utility li.recently-viewed > a.nav-link").click(function (e) {
        e.preventDefault();
    });
    
});
function BindPrimaryNavigation() {
    //hover events
    var $desktopNavWrapper = $("nav.nav-primary .nav-wrapper");
    //nav menu
    var primaryNavController = {
        $header: $("header"),
        $primaryNav: $desktopNavWrapper.find("#primary-navigation"),
        $dropdown: null,
        $dropdownToggle: null,
        $primaryNavRoot: null,
        showClass: "hovered",
        init: function () {
            var self = this;
            self.$dropdown = self.$primaryNav.find(".nav-item.dropdown");
            self.$dropdownToggle = self.$primaryNav.find(".nav-link.dropdown");
            self.$primaryNavRoot = self.$primaryNav.children(".nav-item").siblings().not('.dropdown');
        },
        bind: function () {
            var self = this;
            if ($(window).width() > 767) {
                self.$dropdown.hover(
                    function () {

                        //hide others
                        self.$dropdown.removeClass(self.showClass);
                        self.$dropdownToggle.attr("aria-expanded", "false");

                        const $this = $(this);
                        $this.addClass(self.showClass);
                        $this.find(self.$dropdownToggle).attr("aria-expanded", "true");
                    },
                    function () {
                        //const $this = $(this);
                        //$this.removeClass(self.showClass); //.removeClass("tapped");
                        //$this.find(self.$dropdownToggle).attr("aria-expanded", "false");
                    }
                );

                self.$header.hover(function () { }, function () {
                    //leave
                    self.$dropdown.removeClass(self.showClass);
                    self.$dropdownToggle.attr("aria-expanded", "false");
                });
                self.$primaryNavRoot.hover(function () {
                    //hide all
                    self.$dropdown.removeClass(self.showClass);
                    self.$dropdownToggle.attr("aria-expanded", "false");
                });


                //custom the tab focuses, works well
                self.$primaryNav.on('focusin', '> .nav-item.dropdown > .nav-link.dropdown', function (e) {
                    var $dropdownParent = $(this).closest("li.dropdown");
                    //stop the link from receiving the event
                    e.preventDefault();
                    e.stopPropagation();

                    //if they've already enabled the menu lets toggle it
                    if ($dropdownParent.hasClass(self.showClass)) {
                        $dropdownParent.removeClass(self.showClass);
                        self.$dropdownToggle.attr("aria-expanded", "false");
                        return;
                    }

                    //Otherwise remove the class from any dropdowns showing it and add it to this item
                    self.$dropdown.removeClass(self.showClass);
                    self.$dropdownToggle.attr("aria-expanded", "false");
                    $dropdownParent.addClass(self.showClass);
                    $(this).attr("aria-expanded", "true");
                });

                //custom touch devices, double tap on dropdowns to navigate
                //great solution
                self.$dropdown.on('touchstart', function (e) {
                    const $this = $(this);
                    //if they've already enabled the menu lets toggle it
                    if (!$this.hasClass(self.showClass)) {
                        //hide all others
                        self.$dropdown.removeClass(self.showClass); //.removeClass("tapped");
                        self.$dropdownToggle.attr("aria-expanded", "false");
                        //show this
                        $this.addClass(self.showClass);
                        $this.children(".nav-link.dropdown").attr("aria-expanded", "true");

                        //stop the link from receiving the event
                        e.preventDefault();
                        e.stopPropagation();
                    }
                });
                $("body").off('touchstart').on('touchstart', function (e) {
                    const $this = $(this);
                    if ($(e.target).closest("#primary-navigation").length < 1) {
                        //remove
                        self.$dropdown.removeClass(self.showClass);
                        self.$dropdownToggle.attr("aria-expanded", "false");
                        return;
                    }
                });

            } else {
                self.$dropdown.off("mouseenter mouseleave");
            }
        }
    };
    primaryNavController.init();
    primaryNavController.bind();
    $(window).resize(function () {
        primaryNavController.bind();
    });
    //end nav menu

    //tablet size scroll events
    $desktopNavWrapper.find(".scroll-to-dates").click(function (e) {
        e.preventDefault();
        $desktopNavWrapper.find(".search-bar").addClass("scrolled-to-dates");
    });
    $desktopNavWrapper.find(".scroll-to-location").click(function (e) {
        e.preventDefault();
        $desktopNavWrapper.find(".search-bar").removeClass("scrolled-to-dates");
    });

}

function adjustMenu() {
    var i = 1;

    $(".megamenu:visible").each(function (e) {


        var elm = $(this);
        var mm = $(elm).children('.dropdown-menu').first();

        //mm.removeAttr('style');
        $(mm).removeClass('center');
        $(elm).css('position', 'relative');

        var off = $(mm).offset();
        var l = off.left; // position of right of parent item
        var ddCenter = (Math.ceil(($(elm).width() / 2)) + l); // Center point of the parent menu item from the left
        var w = $(mm).outerWidth(); // mEGA MENU WIDTH

        var docH = $(document).height();
        var docW = $(document).width();

        //console.log('right edge -' + rightEdge + ' right offset - ' + rightEdgeOffset);
        //console.log(elm.text().trim().substr(0, 15));
        //console.log("left offset - " + l + " docW - " + docW);

        var offsetVal = docW - (l + w);

        //console.log(i + " nav width: " + ($('nav').width() * .5));
        //console.log(i + " item width: " + w);
        //console.log(offsetVal);

        if (l < 0) {
            //alert("yep" + elm.text());
            $(mm).addClass('center');
            $(elm).css('position', 'static');
            //var rightText = "right: " + offsetVal + "px!important";
            //$(mm).css('right', (15 - offsetVal) + "px");

        }
        else {
            //$(mm).css('right', 0);
            $(mm).removeClass('center');
            $(elm).css('position', 'relative');
        }
        i++;
    });
}
